<template lang="pug">
	article#carrinho
		section#cart
			.wrapper
				h2 Seu Carrinho
				table(v-if="$carrinho.carrinho && $carrinho.carrinho.produtos.length")
					thead
						tr
							th Item
							th Categoria
							th Preço
							th Subtotal
					tbody
						tr(v-for="item, index in $carrinho.carrinho.produtos", :class="{ esgotado: item.produto.esgotado }")
							td(data-header="Item").item
								div.img
									img(:src="item.produto.produto.imagem || require('@images/icones/icone-foto-carrinho.jpg')").evento
									button(@click="$carrinho.remover(item.produto_id)")
										img(:src="require('@images/icones/fechar.png')")
								span {{ item.produto.produto.nome }}
								span.esgotado(v-if="item.produto.esgotado")  Esgotado
							td(data-header="Categoria") {{ item.produto.produto.categoria_nome }}
							td(data-header="Preço") R$ {{ item.produto.preco_unidade | fixed }}
							// td(data-header="Quantidade") 
							// 	button(@click="$carrinho.atualizar(item.produto_id, 'remover')", :disabled="item.produto.quantidade <= 1")
							// 		img(:src="require('@images/icones/icone-menos.png')")
							// 	input(type="number", v-model="item.produto.quantidade" name="quantidade" id="quantidade" min=1)
							// 	button(@click="$carrinho.atualizar(item.produto_id, 'adicionar')")
							// 		img(:src="require('@images/icones/icone-mais.png')")
							td(data-header="Subtotal") R$ {{ item.produto.preco_total | fixed }}
							td(@click="$carrinho.remover(item.produto_id)", title="Remover produto").apagar
								include ../../assets/images/icones/icone-lixeira.svg
				div.botoes
					router-link(:to="{name: 'eventos'}").continue Continue comprando
					router-link(:to="{name: 'finalizeCompra'}", v-if="$carrinho.carrinho && $carrinho.carrinho.produtos.length && !$carrinho.isAnyProdutoEsgotado").finalize Finalizar compra
</template>

<script>
export default {
	filters: {
        fixed(value) {
            let numero = parseFloat(value).toFixed(2);
            numero = new Number(numero).toLocaleString("pt-BR");
            if (numero.search(',') == -1)
                numero = numero + ",00"
            return numero;
        }
    }
}
</script>

<style lang="stylus" scoped src="./Carrinho.styl"></style>